import styled from "styled-components";
import { Paragraph } from "../../style/text";

export const ListItem = styled.div`
  align-content: left;
  justify-items: center;
  justify-self: center;
  margin: 0 auto;
`;

export const ItemDescription = styled(Paragraph)`
  width: 90%;
  text-align: left;
  max-width: 40rem;
  margin: 0 auto;
`;
