import React, { Fragment } from "react";
import "babel-polyfill";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga";

ReactGA.initialize("UA-118977695-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const history = createBrowserHistory();
history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

import NotFound from "./pages/404/index";
import ResourcesMoonpig from "./pages/resourcesMoonpig/index";
import ResourcesHyve from "./pages/resourcesHyve/index";
import ResourcesVorboss from "./pages/resourcesVorboss/index";
import Tools from "./pages/tools/index";
import Reading from "./pages/reading/index";

const AppRouter = () => (
  <Router history={history}>
    <Switch>
      <Fragment>
        <Switch>
          <Route exact path="/moonpig" component={ResourcesMoonpig} />
          <Route exact path="/hyve" component={ResourcesHyve} />
          <Route exact path="/vorboss" component={ResourcesVorboss} />
          <Route exact path="/tools" component={Tools} />
          <Route exact path="/reading" component={Reading} />
        </Switch>
      </Fragment>
      <Route component={NotFound} />
    </Switch>
  </Router>
);

export default AppRouter;
