import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import Separator from "../../components/separator/index";
import { CenteredSection, TitleSection } from "../../style/base-components";
import { SectionTitle, LongSectionTitle, Paragraph, B } from "../../style/text";
import { Wrapper } from "./style";

class Reading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: -1,
      email: "",
      firstName: "",
      password: "",
      error: false,
      userType: "user",
    };

    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
  }
  componentDidMount() {}

  onChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  onChangeName(event) {
    this.setState({ firstName: event.target.value });
  }

  onChangePassword(event) {
    this.setState({ password: event.target.value });
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Start | Spill</title>
          <meta
            name="description"
            content="Spill lets you message your designated counsellor every day. Affordable talking therapy whenever and wherever you need it."
          />
          <meta
            name="keywords"
            content="Spill,Mental health,counselling,counsellor,chat,online,therapy,help"
          />
        </Helmet>
        <TitleSection>
          <SectionTitle centered>Reading reccomendations</SectionTitle>
        </TitleSection>
        <CenteredSection>
          <Wrapper>
            <LongSectionTitle>Work</LongSectionTitle>
            <Paragraph>
              <B>
                Starter - this is basic emotional intelligence stuff everyone
                should read.
              </B>
              1.{" "}
              <a href="https://www.theschooloflife.com/thebookoflife/category/work">
                {" "}
                The Book of Life by School of Life.
              </a>{" "}
              This collection of short articles is a great place to start
              learning more about our feelings at work. Read if you want to feel
              informed.
              <br />
              <br />
              2.{" "}
              <a href="https://www.goodreads.com/book/show/5293573-the-pleasures-and-sorrows-of-work">
                The Pleasures and Sorrows of Work by Alain de Botton.
              </a>{" "}
              An accessible non-fiction analysis of the hopes and anxieties we
              bring to the activity that takes up most of our waking hours. Read
              if you want to feel inspired and knowledgeable.
              <br />
              <br />
              3.{" "}
              <a href="https://www.goodreads.com/book/show/16071764-lean-in">
                Lean In: Women, Work, and the Will to Lead by Sheryl Sandberg.
              </a>{" "}
              This non-fiction account of women&apos;s life in the workplace is
              a good grounding on the topic, whether you are a woman or a man.
              Read to feel inspired.
              <br />
              <br />
              <B>Advanced - for the emotionally sophisticated.</B>
              1.{" "}
              <a href="https://www.goodreads.com/book/show/59649.Working">
                Working: People Talk about What They Do All Day and How They
                Feel about What They Do by Studs Terkel.
              </a>{" "}
              This non-fiction collection of interviews is a heartwarming and
              inspiring survey of why people do what they do, where they find
              inspiration, and what keeps them happy in their working lives.
              Read if you want to feel love for the human kind.
              <br />
              <br />
              2.{" "}
              <a href="https://www.goodreads.com/book/show/26156469-never-split-the-difference">
                Never Split the Difference: Negotiating as if Your Life Depended
                on It by Chris Voss.
              </a>{" "}
              This entertaining non-fiction account of an ex-FBI&apos;s manual
              for negotiating with others makes for a pretty helpful tool in
              everyday conversation. Read if you want to feel powerful.
              <br />
              <br />
              3.{" "}
              <a href="https://www.goodreads.com/book/show/629.Zen_and_the_Art_of_Motorcycle_Maintenance">
                Zen and the Art of Motorcycle Maintenance: An Inquiry Into
                Values by Robert Pirsig.
              </a>{" "}
              This American cult-classic is an uplifting account of how to find
              meaning in the everyday. Read if you want to feel inspired.
            </Paragraph>
          </Wrapper>
        </CenteredSection>
        <Separator />
        <CenteredSection>
          <Wrapper>
            <LongSectionTitle>Relationships</LongSectionTitle>
            <Paragraph>
              <B>
                Starter - this is basic emotional intelligence stuff everyone
                should read.
              </B>
              1.{" "}
              <a href="https://www.goodreads.com/book/show/23425.Status_Anxiety">
                Status Anxiety by Alain de Botton.
              </a>{" "}
              An accessible non-fiction analysis of this central emotion in
              human life, its history and its impact in the modern world. Read
              if you want to feel inspired and knowledgeable.
              <br />
              <br />
              2.{" "}
              <a href="https://www.goodreads.com/book/show/27485.Mating_in_Captivity">
                Mating in Captivity: Reconciling the Erotic and the Domestic by
                Esther Perel.
              </a>{" "}
              This non-fiction book by the famed psychotherapist is a must read
              for anyone considering having, or staying in, a relationship.
              Esther&apos;s TED talks and podcasts are also incredible. Read if
              you want to feel inspired and knowledgeable.
              <br />
              <br />
              3.{" "}
              <a href="https://www.goodreads.com/book/show/306940.Impro">
                Impro by Keith Johnstone.
              </a>{" "}
              This short non-fiction account of Mr Johnstone&apos;s
              groundbreaking technique for training improvisation theatre actors
              is mandatory reading in many forward-looking organisations. If you
              live in London, you can also attend impro workshops that use these
              techniques. Read if you want to feel self-aware.
              <br />
              <br />
              <B>Advanced - for the emotionally sophisticated.</B>
              1.{" "}
              <a href="https://www.goodreads.com/book/show/174879.On_Becoming_a_Person">
                On Becoming a Person by Carl Rogers
              </a>{" "}
              This twentieth century classic by the father of the person centred
              psychoanalytic school of thought is a heart-warming guide into how
              to be less judgemental, with yourself as well as others. Read if
              you want to feel good.
              <br />
              <br />
              2.{" "}
              <a href="https://www.goodreads.com/book/show/47883410-the-mom-test">
                The Mom Test: How to talk to customers & learn if your business
                is a good idea when everyone is lying to you by Rob Fitzpatrick.
              </a>{" "}
              Although this short non-fiction business hack book is nothing to
              do with emotions, it&apos;s definitely a good tool for anyone
              dealing with other humans. Read if you want to feel powerful.
              <br />
              <br />
              3.{" "}
              <a href="https://gimletmedia.com/shows/heavyweight">
                Heavyweight by Jonathan Goldstein.
              </a>{" "}
              All four seasons of this Gimlet podcast, which you can find on
              Spotify, are worth a listen. Each episode is a funny heartwarming
              account of reconnection and overcoming. Listen if you want to feel
              happy.
            </Paragraph>
          </Wrapper>
        </CenteredSection>
        <Separator />
        <CenteredSection>
          <Wrapper>
            <LongSectionTitle>Self</LongSectionTitle>
            <Paragraph>
              <B>
                Starter - this is basic emotional intelligence stuff everyone
                should read.
              </B>
              1.{" "}
              <a href="https://www.goodreads.com/book/show/5986375-this-is-water">
                This is Water by David Foster Wallace.
              </a>{" "}
              This short commencement address is an unsentimental analysis of
              the emotional intelligence required to live and thrive in
              &lsquo;the trenches of daily adult existence&rsquo;. Available in
              both print and on YouTube. Read if you want to feel awed.
              <br />
              <br />
              2.{" "}
              <a href="https://www.goodreads.com/book/show/15894631-men-women-and-worthiness">
                Men, Women and Worthiness by Brené Brown.
              </a>{" "}
              This non-fiction overview of the research on shame sheds light
              onto this often destructive emotion and shows how liberating it
              can be to let it go. This author&apos;s TED talks are also
              recommended. Read if you want to feel uplifted, inspired, and
              knowledgeable.
              <br />
              <br />
              3.{" "}
              <a href="https://www.goodreads.com/it/book/show/5752.The_Blank_Slate">
                The Blank Slate by Steven Pinker.
              </a>
              This non-fiction overview of the latest on our understanding of
              the human mind will help you see yourself as never before. Read if
              you want to feel awed.
              <br />
              <br />
              <B>Advanced - for the emotionally sophisticated.</B>
              1.{" "}
              <a href="https://www.goodreads.com/book/show/25899336-when-breath-becomes-air">
                When Breath Becomes Air by Paul Kalanithi.
              </a>{" "}
              An uplifting memoir of the author&apos;s rise to the top of his
              profession and what it all meant when he was diagnosed with stage
              IV metastatic lung cancer. Read if you want to feel a new
              appreciation for being alive.
              <br />
              <br />
              2.{" "}
              <a href="https://www.goodreads.com/book/show/25733573-reasons-to-stay-alive">
                Reasons to Stay Alive by Matt Haig.
              </a>{" "}
              A memoir of the author&apos;s battle with major anxiety and
              depression and how he builds a life in spite of them. Read if you
              want to feel inspired.
              <br />
              <br />
              3.{" "}
              <a href="https://www.goodreads.com/book/show/18693771-the-body-keeps-the-score">
                The Body Keeps the Score by Bessel van der Kolk.
              </a>{" "}
              This non-fiction account by the Harvard psychiatrist looks at how
              mind and body are one and why no attempt at changing our emotions
              can succeed without taking into account &lsquo;the brother
              pig&rsquo;. Read if you want to feel knowledgeable.
            </Paragraph>
          </Wrapper>
        </CenteredSection>
      </Fragment>
    );
  }
}

export default Reading;
