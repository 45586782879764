import React, { Fragment, Component } from "react";
import Fade from "react-reveal/Fade";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import {
  TwoColumnSection,
  CopySection,
  CenteredSection,
  Img,
  TitleSection,
  StyledButton,
} from "../../style/base-components";
import {
  SectionTitle,
  LongSectionTitle,
  Paragraph,
  ThinParagraph,
  LongSectionTitleNoPadding,
} from "../../style/text";
import { Wrapper } from "./style";

import One from "../../assets/resources/1course.png";
import Two from "../../assets/resources/2one.png";
import Three from "../../assets/resources/3message.png";
import Four from "../../assets/resources/4reading.png";
import Five from "../../assets/resources/5tools.png";
import Six from "../../assets/resources/6manager.png";

import Separator from "../../components/separator/index";

class Resources extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Resources | Spill</title>
          <meta
            name="description"
            content="Spill offers message-based therapy to make employees happier and more emotionally intelligent."
          />
          <meta
            name="keywords"
            content="Spill,Mental health,counselling,counsellor,chat,online,therapy,help"
          />
        </Helmet>

        <div>
          <TitleSection>
            <SectionTitle centered>Your Spill resources</SectionTitle>
          </TitleSection>

          <CenteredSection>
            <Wrapper>
              <ThinParagraph>
                Through Spill, you can speak with a qualified therapist about
                anything at all that’s on your mind. You can access this therapy
                free of charge, because your company pays for it on your behalf!
                They’ve chosen to provide you with emotional support through
                Spill — wahoo! 🎉
              </ThinParagraph>
              <br />
              <ThinParagraph>
                To start using the service, just{" "}
                <b style={{ fontWeight: "bold" }}>
                  click &lsquo;Get Started&rsquo; and enter your @vorboss.com
                  email address
                </b>{" "}
                when prompted to sign in. If you have any issues please email{" "}
                <a href="mailto:hi@spill.chat">hi@spill.chat</a>.
              </ThinParagraph>
            </Wrapper>
          </CenteredSection>

          <TwoColumnSection>
            <CopySection>
              <Fade>
                <Img src={One} alt="Onboarding" />
              </Fade>
              <LongSectionTitleNoPadding>
                Request a course of therapy
              </LongSectionTitleNoPadding>
              <ThinParagraph>
                If you’re struggling with low mood or anxiety, or any other
                difficult emotions that impact your daily life, talk to a
                therapist.
              </ThinParagraph>
              <a href="https://app.spill.chat/signin">
                <StyledButton>Get started ➔</StyledButton>
              </a>
            </CopySection>
            <CopySection>
              <Fade>
                <Img src={Two} alt="Video Therapy" />
              </Fade>
              <LongSectionTitleNoPadding>
                Book a one-off therapy session
              </LongSectionTitleNoPadding>
              <ThinParagraph>
                Get emotional coaching or talk through something with a
                therapist, without committing to a whole course of therapy.
              </ThinParagraph>
              <a href="https://app.spill.chat/signin">
                <StyledButton>Get started ➔</StyledButton>
              </a>
            </CopySection>
          </TwoColumnSection>

          <TwoColumnSection>
            <CopySection>
              <Fade>
                <Img src={Three} alt="MainMenu" />
              </Fade>
              <LongSectionTitleNoPadding>
                Send a one-off question to a therapist
              </LongSectionTitleNoPadding>
              <ThinParagraph>
                Ask any question about mental health or emotional wellbeing,
                without commiting to the full process of therapy. You&apos;ll
                get a reply within one working day.
              </ThinParagraph>
              <a href="https://app.spill.chat/signin">
                <StyledButton>Get started ➔</StyledButton>
              </a>
            </CopySection>
            <CopySection>
              <Fade>
                <Img src={Four} alt="Onboarding" />
              </Fade>
              <LongSectionTitleNoPadding>
                Browse our reading recommendations
              </LongSectionTitleNoPadding>
              <ThinParagraph>
                Look through our curated list of resources, designed to help you
                learn and grow.
              </ThinParagraph>
              <a href="/reading">
                <StyledButton>Browse reading ➔</StyledButton>
              </a>
            </CopySection>
          </TwoColumnSection>

          <TwoColumnSection>
            <CopySection>
              <Fade>
                <Img src={Five} alt="Video Therapy" />
              </Fade>
              <LongSectionTitleNoPadding>
                Browse exercises from our mental health and wellbeing toolkit
              </LongSectionTitleNoPadding>
              <ThinParagraph>
                A range of exercises to help you change how you think, work,
                feel and behave.
              </ThinParagraph>
              <a href="/tools">
                <StyledButton>Browse tools ➔</StyledButton>
              </a>
            </CopySection>
            <CopySection>
              <Fade>
                <Img src={Six} alt="Video Therapy" />
              </Fade>
              <LongSectionTitleNoPadding>
                Send a question to our manager advice line
              </LongSectionTitleNoPadding>
              <ThinParagraph>
                Ask a question about how to assist someone else. Feel free to
                use it even if you&apos;re not a manager.
              </ThinParagraph>
              <a href="https://app.spill.chat/signin">
                <StyledButton>Get started ➔</StyledButton>
              </a>
            </CopySection>
          </TwoColumnSection>
        </div>

        <Separator />
        <CenteredSection>
          <Fragment>
            <SectionTitle>FAQs</SectionTitle>

            <Wrapper>
              <LongSectionTitle>Is Spill confidential?</LongSectionTitle>
              <Paragraph>
                Yes. Spill is completely confidential. We only ever share
                aggregate usage statistic with your employer.
                <br />
                <br />
                If you choose to get therapy through Spill then anything you
                tell your therapist is confidential and will never be shared
                with your employer.
              </Paragraph>
              <LongSectionTitle>Who are the Spill therapists?</LongSectionTitle>
              <Paragraph>
                All Spill therapists are registered with BACP, UKCP, or another
                UK Standards Authority governing body. Every Spill Therapist has
                completed at least three years of academic therapy training and
                has at least 100 clinical hours under training supervision. Most
                of our therapists have been practising for decades.
                <br />
                <br />
                Spill&apos;s hiring process is particularly stringent.
                Therapists go through a five-step application process designed
                to test for various competencies. Less than 5% of applicants are
                offered roles at Spill.
              </Paragraph>
              <LongSectionTitle>
                Can I change my therapist if I want to?
              </LongSectionTitle>
              <Paragraph>
                Absolutely. We know that the relationship with your therapist is
                really important. Just email us at hi@spill.chat and we&apos;ll
                handle things from there.
              </Paragraph>
              <LongSectionTitle>
                How are the therapy sessions conducted?
              </LongSectionTitle>
              <Paragraph>
                You get to choose how you want to communicate with your
                therapist. You can pick from phone, video or message-based
                therapy.
                <br />
                <br />
                Once you decide how you want to communicate, you can choose,
                with your therapist how you want to break down the amount of
                therapy you have. You can keep them as weekly 50-minutes
                sessions or turn them into frequent, shorter sessions, or
                receive the equivalent amount of therapy over messages.
              </Paragraph>
              <LongSectionTitle>
                What happens during the initial consultation?
              </LongSectionTitle>
              <Paragraph>
                The goal of the consultation is for you and your therapist to
                meet, discuss the presenting problem, set expectations for your
                time together, set goals where appropriate, and create a plan
                for the coming sessions.
                <br />
                <br />
                <br />
                If you have a question that hasn&apos;t been answered then you
                can email us on hi@spill.chat.
              </Paragraph>
            </Wrapper>
          </Fragment>
        </CenteredSection>
      </Fragment>
    );
  }
}

export default withRouter(Resources);
