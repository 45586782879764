const theme = {
  borderRadius: {},
  borderWidth: {
    s: "0.05rem",
    m: "0.1rem",
    l: "0.4rem",
    xl: "0.75rem",
  },
  colors: {
    primary: {
      extraLight: "#ccf3ee",
      light: "#80E5D8",
      normal: "#35D0BA",
      dark: "#13C2AA",
      extraDark: "#009F89",
    },
    secondary: {
      extraLight: "#fff6e0",
      light: "#FFE6AE",
      normal: "#FEDA88",
      dark: "",
      extraDark: "",
    },
    tertiary: {
      extraLight: "#FFEBE9",
      light: "#FFCFCB",
      normal: "#FFB0A8",
      dark: "#D8766C",
      extraDark: "#AB4338",
    },
    blue: {
      extraLight: "#bfc3d1",
      light: "#797a9c",
      normal: "#041549",
      dark: "",
      extraDark: "",
    },
    white: "#FEFEFD",
    black: "#1C1B1B",
    grey: {
      100: "#FAFAFA",
      200: "#F5F5F5",
      300: "#EEEEEE",
      400: "#E0E0E0",
      500: "#BDBDBD",
      600: "#9E9E9E",
      700: "#757575",
      800: "#616161",
      900: "#424242",
    },
  },
  font: {
    family: {
      Lato: "Lato, 'Lucida Grande', Tahoma, Sans-Serif",
      MarkPro: "MarkPro-Bold, 'Avant Garde', Helvetica, Sans-Serif",
    },
    size: {
      1: "0.75rem",
      2: "0.875rem",
      3: "1rem",
      4: "1.125rem",
      5: "1.25rem",
      6: "1.5rem",
      7: "1.875rem",
      8: "2.25rem",
      9: "3rem",
      10: "3.75rem",
      11: "4.5rem",
      12: "5.25rem",
    },
    weight: {},
  },
  letterSpacing: {
    extraLarge: "0.05em",
    large: "0.03em",
    small: "-0.03em",
    extraSmall: "-0.05em",
  },
  lineHeight: {
    small: 1.2,
    normal: 1.5,
  },
  opacity: {},
  spacing: {
    xs1: "0.25rem", //0
    xs2: "0.5rem",
    xs3: "0.75rem", //2
    s1: "1rem",
    s2: "1.5rem", //4
    s3: "2rem",
    m1: "3rem", //6
    m2: "4rem",
    m3: "6rem", //8
    l1: "8rem",
    l2: "12rem", //10
    l3: "16rem",
    xl1: "24rem", //12
    xl2: "32rem",
    xl3: "40rem",
    xl4: "48rem",
  },
};

export default theme;
