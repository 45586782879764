import React, { Fragment, Component } from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import {
  TwoColumnSection,
  DrawingPadding,
  TitleSection,
  Section,
} from "../../style/base-components";
import { SectionTitle, LongSectionTitle, B } from "../../style/text";
import { ListItem, ItemDescription } from "./style";

import Top from "../../assets/tools/top.png";
import Circle from "../../assets/tools/circle.png";
import Grid from "../../assets/tools/grid.png";

import Separator from "../../components/separator/index";

class Tools extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaign: "",
      showNav: false,
      formSuccess: false,
    };
  }

  componentDidMount() {}

  handleScroll() {
    const scrollY = window.scrollY;
    this.setState({
      showNav: scrollY > this.topSectionHeight,
    });
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Quantiq tools | Spill</title>
          <meta
            name="description"
            content="Spill offers message-based therapy to make employees happier and more emotionally intelligent."
          />
          <meta
            name="keywords"
            content="Spill,Mental health,counselling,counsellor,chat,online,therapy,help"
          />
        </Helmet>
        <TitleSection>
          <SectionTitle centered>Tools and exercises</SectionTitle>
        </TitleSection>

        <TwoColumnSection>
          <LongSectionTitle>
            Circles of Connection Exercise
            <DrawingPadding src={Circle} alt="Circles of Connection Exercise" />
          </LongSectionTitle>

          <ListItem>
            <ItemDescription>
              <B>Step 1: Draw</B>
              Print off or draw out the four circles in the image.
              <br />
              <br />
              <B>Step 2: Write</B>
              Open your calendar and go through the last month. Every time you
              spent time (30min+) interacting with a person or a group of
              people, write their name(s) down in the appropriate circle. If you
              spent time with them multiple times, write a tally next to their
              name, e.g. Charlie III. Remember to include familiar faces —
              hairdressers, clients at work, any significant human interaction —
              as well as long phone or video calls. WhatsApping doesn’t count!
              <br />
              <br />
              <B>Step 3: Analyse</B>
              Look back over the circles. If there are a large number of 1s and
              2s, your time and attention is being allocated in a way that’s
              most likely to make you feel content. If there are mostly 3s and
              4s, something’s not quite right. You’re spending a lot of your
              social time with people you don’t feel connected to. This means
              you might be experiencing the paradoxical situation of feeling
              isolated despite having an active social life.
              <br />
              <br />
              <B>Step 4: Decide</B>
              If that’s the case, think about whether there’s anyone in zones 3
              and 4 you would like to be closer to. Draw arrows from their name
              towards the zone you’d like to get them to. Similarly, if there’s
              anyone in zones 1 or 2 that doesn’t nourish you or bring you joy,
              draw arrows from their name towards the outer zones - or think
              about crossing their name off altogether.
            </ItemDescription>
          </ListItem>
        </TwoColumnSection>

        <Separator />

        <TwoColumnSection>
          <LongSectionTitle>
            100 Blocks Exercise
            <DrawingPadding src={Grid} alt=" 100 Blocks Exercise" />
          </LongSectionTitle>

          <ListItem>
            <ItemDescription>
              <B>Step 1: Print</B>
              Print off two copies of the 100 blocks image shown here, or draw a
              10x10 square similar to it on a blank piece of paper.
              <br />
              <br />
              <B>Step 2: Label</B>
              Fill in the unchangeable blocks (like work and commuting). In all
              the other blocks, write what you think the purpose of each should
              be (e.g. learning, relaxing, connecting with others, being
              active).
              <br />
              <br />
              <B>Step 3: Weigh up</B>
              Think about the trade-offs you&apos;re making, and critique your
              own thinking. Is cooking really something you enjoy enough to
              spend four blocks on? Is 10 mins of meditation a day important
              enough to spend a block on? Then think about which blocks should
              be treated as non-negotiable in their labeled purpose and which
              should be more flexible? Which blocks should be left blank, with
              no assigned purpose at all?
              <br />
              <br />
              <B>Step 4: Label again</B>
              Then fill out another square of 100 blocks with how you spent that
              time yesterday. How are the two squares different? Why?
            </ItemDescription>
          </ListItem>
        </TwoColumnSection>
        <Separator />

        <TwoColumnSection>
          <LongSectionTitle>
            ‘Top, Tail, Learning’ Journalling Technique
            <DrawingPadding
              src={Top}
              alt="‘Top, Tail, Learning’ Journalling Technique"
            />
          </LongSectionTitle>

          <ListItem>
            <ItemDescription>
              Describe one good thing, one bad thing, and one thing you learnt
              from either today (if you’re journalling before bed) or yesterday
              (if you’re journalling in the morning).
              <br />
              <br />
              <B>Tip 1</B>
              We recommend doing it before you go to bed (writing about that
              day) or early in the morning (writing about the previous day).
              <br />
              <br />
              <B>Tip 2</B>
              Keep it short and simple: it’s better to get into the habit of
              journalling often rather than writing a beautifully crafted essay
              every few weeks.
              <br />
              <br />
              <B>Tip 3</B>
              It doesn’t need to be in proper prose: write notes, random word
              associations that come to mind, do little sketches and doodles,
              make graphs…
            </ItemDescription>
          </ListItem>
        </TwoColumnSection>
        <Section />
      </Fragment>
    );
  }
}

export default withRouter(Tools);
