import React from "react";

import {
  Container,
  LogoWrapper,
  Nav,
  NavTitle,
  NavItem,
  NavLogo,
  IconWrapper,
  BottomFooter,
  BottomFooterText,
  FooterContent,
  BottomFooterLink,
} from "./style";
import logo from "../../assets/logo/logo-blob-teal.png";
import {
  InstagramIcon,
  MediumIcon,
  LinkedInIcon,
} from "../../assets/icons/index";

const Footer = () => (
  <Container>
    <FooterContent>
      <LogoWrapper>
        <NavLogo src={logo} alt="Spill logo" />
      </LogoWrapper>

      <Nav>
        <NavTitle>company</NavTitle>
        <NavItem href="https://www.spill.chat/about">About us</NavItem>
        <NavItem href="https://www.spill.chat/about#team">Team</NavItem>
        <NavItem href="https://angel.co/spillchat/jobs" target="__blank">
          Careers
        </NavItem>
      </Nav>

      <Nav>
        <NavTitle>content</NavTitle>
        <NavItem
          target="__blank"
          href="https://www.instagram.com/spillstagram_/channel/"
        >
          Video
        </NavItem>
        <NavItem target="__blank" href="https://medium.com/spill-stories">
          Blog
        </NavItem>
        <NavItem
          target="__blank"
          href="https://www.spill.chat/covid-emotional-wellbeing"
        >
          COVID-19
        </NavItem>
      </Nav>

      <IconWrapper>
        <a
          href="https://www.instagram.com/spillstagram_/"
          target="__blank"
          aria-label="Instagram"
        >
          <InstagramIcon size={30} />
        </a>
        <a
          href="https://www.linkedin.com/company/spillchat"
          target="__blank"
          aria-label="LinkedIn"
        >
          <LinkedInIcon size={30} />
        </a>
        <a
          href="https://medium.com/spill-stories"
          target="__blank"
          aria-label="Medium"
        >
          <MediumIcon size={30} />
        </a>
      </IconWrapper>
    </FooterContent>

    <BottomFooter>
      <BottomFooterText>© 2020 Spill App Ltd</BottomFooterText>
      <BottomFooterText>
        <BottomFooterLink
          href="https://www.notion.so/spill/Spill-Platform-User-Terms-Conditions-504b0cb3a7f24a08872cdc25b146addd"
          target="__blank"
        >
          {" "}
          Terms of service{" "}
        </BottomFooterLink>
        |
        <BottomFooterLink
          href="https://www.notion.so/spill/Privacy-Policy-4109557a65d440d69b70f443c2b14a4a"
          target="__blank"
        >
          {" "}
          Privacy policy{" "}
        </BottomFooterLink>
        |
        <BottomFooterLink href="mailto:hi@spill.chat">
          {" "}
          Contact us{" "}
        </BottomFooterLink>
      </BottomFooterText>
    </BottomFooter>
  </Container>
);

export default Footer;
