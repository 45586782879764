import styled from "styled-components";
import devices from "../../style/devices";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${devices.tablet} {
    max-width: 55rem;
  }
`;
