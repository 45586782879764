import React from "react";

import {
  Container,
  DiamondWrapper,
  PinkDiamond,
  YellowDiamond,
  TealDiamond,
  BlueDiamond,
  Line,
} from "./style";

const Separator = () => (
  <Container>
    <Line />
    <DiamondWrapper>
      <PinkDiamond />
      <YellowDiamond />
      <TealDiamond />
      <BlueDiamond />
    </DiamondWrapper>
    <Line />
  </Container>
);

export default Separator;
