import React from "react";
import { HoverSvg } from "./style";
import PropTypes from "prop-types";

export const InstagramIcon = ({ size = 30 }) => (
  <HoverSvg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60"
    width={size}
    height={size}
  >
    <g>
      <path d="M30.35 24.555c-3.359-.192-5.662 2.634-5.794 5.142-.171 3.243 2.655 5.856 5.456 5.722 2.949.084 5.274-2.498 5.426-5.063.198-3.38-2.624-5.662-5.088-5.801z" />
      <path d="M41.238 18.124c-1.034-.877-2.223-1.438-3.604-1.443-4.921-.013-9.842.007-14.763-.015-1.089-.004-2.108.189-3.049.732-1.956 1.13-3.086 2.802-3.13 5.087-.047 2.502-.011 5.006-.011 7.508l.001 7.765c0 .127-.014.258.008.381.275 1.471.918 2.748 2.07 3.723 1.034.879 2.223 1.441 3.604 1.445 5.119.012 10.237.004 15.356.004.142 0 .287.016.425-.01 1.47-.275 2.747-.916 3.723-2.07.878-1.033 1.44-2.223 1.443-3.604.013-5.117.006-10.235.005-15.355 0-.142.017-.286-.01-.423-.273-1.473-.914-2.748-2.068-3.725zM38.42 30.708c-.265 3.894-3.723 7.822-8.422 7.697-4.346.205-8.79-3.713-8.428-9.062.256-3.77 3.622-8.111 9.092-7.773 3.725.23 8.136 3.587 7.758 9.138zm.34-7.264c-1.1.029-2.211-.789-2.146-2.209-.149-1.048.984-2.207 2.204-2.135 1.261.074 2.122.989 2.114 2.178-.007 1.221-.797 2.13-2.172 2.166z" />
      <path d="M30 3.167c-14.822 0-26.84 12.017-26.84 26.84 0 14.821 12.018 26.839 26.84 26.839 14.825 0 26.84-12.018 26.84-26.839 0-14.823-12.015-26.84-26.84-26.84zm16.295 35.399c-.021.018-.057.031-.059.053-.169 1.494-.757 2.826-1.638 4.023-1.027 1.398-2.337 2.459-3.979 3.055-.665.242-1.364.396-2.046.592H21.427c-.018-.021-.033-.057-.053-.059-1.495-.17-2.826-.758-4.025-1.639-1.398-1.027-2.457-2.336-3.054-3.98-.241-.664-.396-1.363-.591-2.045V21.422c.021-.019.057-.035.06-.055.168-1.495.755-2.825 1.636-4.025 1.027-1.398 2.338-2.457 3.98-3.053.666-.242 1.364-.397 2.047-.592h17.146c.016.021.031.057.052.06 1.495.168 2.826.756 4.024 1.636 1.399 1.027 2.459 2.337 3.055 3.98.241.666.396 1.365.591 2.048v17.145z" />
    </g>
  </HoverSvg>
);

InstagramIcon.propTypes = { size: PropTypes.number };

export const LinkedInIcon = ({ size = 30 }) => (
  <HoverSvg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 60 60"
  >
    <path d="M30 3.154c-14.822 0-26.84 12.017-26.84 26.84 0 14.82 12.018 26.838 26.84 26.838 14.825 0 26.84-12.018 26.84-26.838 0-14.824-12.015-26.84-26.84-26.84zm-8.446 28.885c-.027 4.496-.065 8.996-.099 13.492h-7.172c-.033-3.439-.079-6.877-.098-10.316-.016-3.108-.02-6.219.007-9.33.012-1.513.049-1.534 1.593-1.562a99.34 99.34 0 0 1 4.367.004c1.307.034 1.366.081 1.39 1.36.037 2.116.024 4.235.012 6.352zm-3.683-9.49a4.046 4.046 0 1 1-.002-8.092 4.046 4.046 0 0 1 .002 8.092zm27.485 23.004c-2.122-.061-4.248-.021-6.373-.021-.034-3.582-.045-7.166-.117-10.746-.018-.844-.137-1.715-.391-2.517-.787-2.482-3.271-3.005-5.07-1.144-.788.814-1.11 1.731-1.108 2.863.006 3.848-.053 7.693-.086 11.543h-7.172c-.033-2.119-.089-4.238-.096-6.357-.016-4.443-.018-8.885.006-13.328.007-1.435.073-1.489 1.438-1.519 1.591-.035 3.183 0 4.774-.007.693-.003 1.158.214 1.144 1.01-.021 1.139.401.551.832.232 3.577-2.656 8.608-2.144 11.229 1.172.822 1.041 1.204 2.43 1.788 3.658 0 4.779-.02 9.56.021 14.341.008.693-.122.837-.819.82z" />
  </HoverSvg>
);
LinkedInIcon.propTypes = { size: PropTypes.number };

export const MediumIcon = ({ size = 30 }) => (
  <HoverSvg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 60 60"
  >
    <path d="M29.999 3.154c-14.822 0-26.84 12.017-26.84 26.84 0 14.82 12.018 26.838 26.84 26.838 14.825 0 26.84-12.018 26.84-26.838 0-14.824-12.015-26.84-26.84-26.84zm16.888 17.9c-.225 0-.449-.003-.674 0-2.439.045-2.703.301-2.703 2.643 0 4.435.043 8.87-.026 13.303-.021 1.422.677 1.875 1.888 1.949.504.029 1.01.004 1.516.004v3.717H34.729v-3.662c.888-.045 1.546-.076 2.281-.111V22.501c-1.134 3.816-2.23 7.364-3.237 10.938-.86 3.062-1.617 6.152-2.421 9.23h-5.066c-1.815-6.529-3.633-13.057-5.448-19.585l-.211.072v15.731a856.1 856.1 0 0 0 2.282.141v3.641h-9.795v-3.717c2.252-.023 2.363-.133 2.363-2.41.002-4.379.009-8.757-.002-13.136-.005-1.882-.395-2.254-2.361-2.354v-3.715h12.7l3.814 13.31c1.356-3.753 2.44-7.431 3.396-11.141.392-1.522.93-2.312 2.751-2.223 3.696.18 7.407.053 11.113.053l-.001 3.718z" />
  </HoverSvg>
);
MediumIcon.propTypes = { size: PropTypes.number };
