import styled from "styled-components";
import devices from "../../style/devices";
import { SubTitle } from "../../style/text";

export const Container = styled.footer`
  background-color: ${({ theme }) => theme.colors.blue.normal};
  padding: ${({ theme }) => theme.spacing.s1};
  padding-top: ${({ theme }) => theme.spacing.m3};
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;

  @media ${devices.tablet} {
    padding: 4rem 6rem 2rem;
    min-height: 70vh;
  }

  @media ${devices.desktop} {
    padding-top: ${({ theme }) => theme.spacing.l1};
    padding-right: ${({ theme }) => theme.spacing.l2};
    padding-left: ${({ theme }) => theme.spacing.l2};
    padding-bottom: ${({ theme }) => theme.spacing.s3};
    min-height: 30rem;
  }
`;

export const FooterContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: ${({ theme }) => theme.spacing.xs3};
  grid-row-gap: ${({ theme }) => theme.spacing.s3};
  padding-bottom: ${({ theme }) => theme.spacing.s1};
  max-width: 425px;

  @media ${devices.tablet} {
    grid-row-gap: ${({ theme }) => theme.spacing.m2};
    width: 100%;
  }

  @media ${devices.desktop} {
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: ${({ theme }) => theme.spacing.s3};
    grid-row-gap: ${({ theme }) => theme.spacing.s1};
    padding-bottom: 0;
    max-width: 1024px;
    width: 100%;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 3;

  @media ${devices.desktop} {
    grid-column: 1;
  }
`;

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-items: start;
`;

export const NavTitle = styled(SubTitle)`
  color: ${({ theme }) => theme.colors.blue.light};
  padding-bottom: 1rem;
  font-size: ${({ theme }) => theme.font.size[2]};

  @media ${devices.desktop} {
    padding-bottom: 2rem;
  }
`;

export const NavItem = styled.a`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.font.size[1]};
  padding-bottom: 1.5rem;
  font-family: MarkPro-Book;
  text-decoration: none;
  line-height: ${({ theme }) => theme.lineHeight.small};

  &:hover {
    color: ${({ theme }) => theme.colors.primary.normal};
    text-decoration: none;
  }

  @media ${devices.desktop} {
    font-size: ${({ theme }) => theme.font.size[3]};
    padding-bottom: 2rem;
  }
`;

export const NavLogo = styled.img`
  width: 2rem;
  padding-bottom: 1rem;
  justify-self: start;

  @media ${devices.tablet} {
    width: 2.5rem;
  }
  @media ${devices.desktop} {
    width: 3rem;
  }
`;

export const IconWrapper = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(3, 1fr);
  grid-column: 1 / span 3;
  justify-self: center;
  grid-column-gap: 1rem;
  margin-top: 3rem;
  grid-auto-rows: 1fr;

  @media ${devices.tablet} {
    margin: 0;
  }

  @media ${devices.desktop} {
    grid-column: 6;
    margin: 0;
    width: 100%;
    justify-self: end;
  }
`;

export const BottomFooter = styled.footer`
  width: 100%;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  flex-direction: column;

  @media ${devices.desktop} {
    flex-direction: row;
    max-width: 1024px;
  }
`;

export const BottomFooterText = styled.span`
  color: ${({ theme }) => theme.colors.blue.extraLight};
  font-family: MarkPro-Book;
  font-size: 0.8rem;
  text-align: center;
  padding-bottom: 0.5rem;

  @media ${devices.desktop} {
    text-align: left;
    padding: 0;
  }
`;

export const BottomFooterLink = styled.a`
  color: ${({ theme }) => theme.colors.blue.extraLight};
  font-family: MarkPro-Book;
  font-size: 0.8rem;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.primary.normal};
    text-decoration: none;
  }
`;
