import styled from "styled-components";
import devices from "./devices";

export const SectionTitle = styled.h1`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.font.family.MarkPro};
  font-weight: bold;
  font-size: ${({ theme }) => theme.font.size[7]};
  text-align: ${({ centered }) => (centered ? "center" : "left")};
  letter-spacing: ${({ theme }) => theme.letterSpacing.small};
  padding-bottom: ${({ theme }) => theme.spacing.s1};
  line-height: normal;

  @media ${devices.tablet} {
    font-size: ${({ theme }) => theme.font.size[8]};
    max-width: 50vw;
    padding-bottom: ${({ theme }) => theme.spacing.s2};
  }

  @media ${devices.desktop} {
    font-size: ${({ theme }) => theme.font.size[8]};
  }
`;

export const LongSectionTitle = styled.h2`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.font.family.Lato};
  letter-spacing: 0;
  font-weight: normal;
  line-height: 1.3;
  padding-top: ${({ theme }) => theme.spacing.s3};
  padding-bottom: ${({ theme }) => theme.spacing.s1};
  font-size: ${({ theme }) => theme.font.size[7]};

  @media ${devices.tablet} {
    font-size: ${({ theme }) => theme.font.size[8]};
    padding-top: ${({ theme }) => theme.spacing.m1};
    padding-bottom: ${({ theme }) => theme.spacing.s2};
  }
`;

export const LongSectionTitleNoPadding = styled.h2`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.font.family.Lato};
  letter-spacing: 0;
  font-weight: normal;
  line-height: 1.3;
  padding-bottom: ${({ theme }) => theme.spacing.s1};
  font-size: ${({ theme }) => theme.font.size[5]};

  @media ${devices.tablet} {
    font-size: ${({ theme }) => theme.font.size[8]};
    padding-bottom: ${({ theme }) => theme.spacing.s2};
  }
`;

export const Paragraph = styled.p`
  font-family: ${({ theme }) => theme.font.family.Lato};
  color: ${({ theme }) => theme.colors.grey[900]};
  line-height: ${({ theme }) => theme.lineHeight.normal};
  font-size: ${({ theme }) => theme.font.size[4]};
  text-align: ${({ centered }) => (centered ? "center" : "left")};

  @media ${devices.desktop} {
    font-size: ${({ theme }) => theme.font.size[4]};
  }
`;

export const ThinParagraph = styled(Paragraph)`
  max-width: 90%;
`;

export const SubTitle = styled.h2`
  font-family: ${({ theme }) => theme.font.family.MarkPro};
  font-weight: bold;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.grey[500]};
  letter-spacing: ${({ theme }) => theme.letterSpacing.large};
  padding-bottom: ${({ theme }) => theme.spacing.xs2};
  font-size: ${({ theme }) => theme.font.size[2]};
  text-align: ${({ centered }) => (centered ? "center" : "left")};
  @media ${devices.tablet} {
  }

  @media ${devices.desktop} {
    padding-bottom: ${({ theme }) => theme.spacing.s1};
  }
`;

export const B = styled.p`
  font-weight: bold;
`;
